import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// import { srConfig } from '@config';
// import sr from '@utils/sr';
import { Layout } from '@components';

// import { usePrefersReducedMotion } from '@hooks';



const ArchivePage = ({ location }) => {
  
  const revealTitle = useRef(null);
  // const revealTable = useRef(null);
  // const revealProjects = useRef([]);
  // const prefersReducedMotion = usePrefersReducedMotion();

  // useEffect(() => {
  //   if (prefersReducedMotion) {
  //     return;
  //   }

  //   sr.reveal(revealTitle.current, srConfig());
  //   sr.reveal(revealTable.current, srConfig(200, 0));
  //   revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 10)));
  // }, []);

  return (
    <Layout location={location}>
      <Helmet title="My Journey" />

      <main>
        <header ref={revealTitle}>
          <h1 className="big-heading">My Journey</h1>
          <p className="subtitlej">Lately, I have been speaking to a bunch of folks, and most of them asked me about my journey till now, What fuels me ?</p>
          <p className="subtitlej">Before I start, I would like to acknowledge that I am very grateful to the people who have trusted me and gave me opportunities to learn and grow, it is their humbleness and empathy that have helped me till now. Let us now connect some dots.</p>
          <p></p>
          <p></p>
          <p></p>
          <h2 className="big-headingj"> Pre 2016 | School Days</h2>
          <p></p>
          <p> Thanks to <a href='https://www.amazon.in/How-Things-Work-Chris-Oxlade/dp/0754810879'>How Things Work by Chris Oxlade</a> encyclopedia, I wanted to know how things work by breaking and building things. I was always curious in nature, This made me join GLOBERS, the quiz club of my High School where I got a chance to represent my school in various inter-school competitions.
          World of technology always fascinated me, this made me embark on the path of Computer Science. 
          </p>
          <p></p>
          <h2 className="big-headingj"> 2016 - 2020 | University Days</h2>
          <p></p>
          <p>
          After unsuccesful attempt to get an admission into IIT ( MIT alternative of India ), I joined PES University, Bangalore to pursue Information Science and Engineering.
          It was the time when Data Science was into the headlines (just how web3 is these days), My curiosity made me try my hands at the Data Science and ML field, doing courses by <a href='https://en.wikipedia.org/wiki/Andrew_Ng'>Andrew Ng</a>. and making projects alongside. <a href ='https://github.com/yashraj2312/yashraj2312.github.io/tree/master/broeye'>Brother-Eye</a> being one of them. </p>
          <p>
          This was also the time when I was attending various conferences, events across Bangalore. <a href ='https://www.jetbrains.com/'>JetBrains</a> workshop on their Dev-tools simply blew me away and that's when I realized building applications for the mobile and web is thr real deal.
          Although, web development wasn't part of the curriculum that year I started learning it with the help of Youtube, Udemy and guidance of amazing faculties in my University.
          Soon, I realized I need to do real-world projects in order to cement my learning.</p>
          <p>
            During my 2nd Year of college i.e Nov 2018 , I reached out to <a href ='https://helpinsuffering.org/'>Help in Suffering</a> to build their website, seeing my passion for learning and drive they gave me chance to work on it.
            By the end of the project, my inclination towards engineering grew manifold. 
          </p>
          <p>
          Fast forward to next year i.e 2019 Dec. I was actively volunteering at IEEE PESIT Student Chapter, later I was elevated to the position of head of Personnel where I built and actively managed a team of 60+ strong volunteers for teams across IT, Logistics, Hospitality, and Design. 
          During my stint at IEEE, we as a team closed sponsors for the annual flagship event of IEEE PESIT Chapter <a href = 'https://kalpana2019.netlify.app/#'>Kalpana</a> promoting science and technology amongst underprivileged kids.
          </p>
          <p>
          Being in <em>Namma Bengaluru ecosystem</em> revamped my mindset about engineering.
          </p>
          <p>
            After closely following Startup and tech ecosystem, I had a paradigm shift around engineering practices and started giving more attention to <em>What, How and Why</em> factor of Products.<br></br>
            Fast forward to early 2020, I cold-emailed <a href ='https://www.linkedin.com/in/saimsalikkhan/'>Saim Khan</a> for an internship in Marketing and Sales(yup, I wanted to learn how a product fetch money). It was quite a different experience which taught me multiple people skills.<br></br>
            During this period I was also researching around various concepts, insights and strategies adopted by companies which I used pen down in my <a href ='https://yashrajnyc.wixsite.com/post/articles'>Blog.</a>
          </p>
          <p>
            Sometime around Feb 2020, It was placement season and Schlumberger visited for campus placements and I made it through it. I was offered the role of Software Engineer only to be rescinded much later owing to Covid-19 pandemic.
            That's when I decided to join Foxmula.
          </p>
          <p></p>
          <h2 className="big-headingj"> 2020 - 2022 </h2>
          <p></p>
          <p>
            During my tenure at Foxmula, I touched upon a lot of surfaces in terms of technologies, backend, frontend, and others.
            Majority of my work revolved around <em>Java Dropwizard Framework, Maven Dependencies, Google guice dependency Injection, MongoDB, REST APIs, Microservices, React.JS, Redux, JUnits, Mockito, Node.js, Express.JS.</em><br></br>

            After moving to EOX Vantage, I was working on maintenance of their flagship product and also got a chance to work on few REST API Microservices. The stack involved were <em>php, Laravel, Camunda BPMN, Socketio, MySQL and ActiveMQ.</em> 
          </p>
          <p>
            BigBinary is the place that helped me with everything I had in my mind while joining. I was introduced to techniques of building software the right way, doing <span className="subtitlej">Test-Driven Development, well-written documentation, writing decision records</span>, and many more.<br></br>
            I got introduced to the structured way of doing things. Now I look upon building software as a craftsman's work.
          </p>
          <p>
          I also had the flexibility to get involved in a lot of things - <span className = "subtitlej"> Requirements discussion, conversations around Tech-Debts, collaborating with folks globally with different culture and working style</span>. The ideas I had were listened to, some adopted some explained why that is not relevant for the Project. A lot of brainstorming with the Product Managers, where I could safely lay down my thoughts if I had a different opinion.<br></br>
          A lot of learning happened which I am always grateful for.
          </p>

          <p>
          </p>
          <h2 className="big-headingj"> 2022 -  </h2>
          <p></p>
          <p>
          Let's see what's there in store for me now. I am currently looking out.
          After working in the B2C vertical for quite some time now, I'm open to exploring the B2B markets.</p>
          <p>
          Lastly, if you are still reading this, I would love to talk to you, understand  <span className = "subtitlej">what you are doing, why you are doing and how can I help you.</span>
          </p>





        </header>

        
      </main>
    </Layout>
  );
};
ArchivePage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default ArchivePage;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/projects/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            tech
            github
            external
            ios
            android
            company
          }
          html
        }
      }
    }
  }
`;
